<template>
  <div class="w-11/12 mx-auto">
    <div>
      <div class="flex items-center">
        <h1 class="text-grey mr-2">Interview</h1>
        <span
          class="material-icons text-grey cursor-pointer"
          @click="showInformation"
        >
          info
        </span>
        <base-button>New Note</base-button>
      </div>
      <transition name="fade" mode="out-in">
        <p class="text-grey" v-if="showInfo">
          This is the info about this policy it tells you things like who
          created the policy and why.
        </p>
      </transition>
      <div class="flex justify-between bg-lightGrey p-5 rounded-xl my-5 w-96">
        <p class="text-grey">Select Interview Type</p>
        <div
          class="grid grid-cols-2 grid-rows-2 gap-y-2 gap-x-3 text-grey place-content-center"
        >
          <div class="flex items-center gap-x-2">
            <input type="radio" name="interviewType" id="digital" />
            <label for="digital">Digital</label>
          </div>
          <div class="flex items-center gap-x-2">
            <input type="radio" name="interviewType" id="first" />
            <label for="first">First</label>
          </div>
          <div class="flex items-center gap-x-2">
            <input type="radio" name="interviewType" id="physical" />
            <label for="physical">Physical</label>
          </div>
          <div class="flex items-center gap-x-2">
            <input type="radio" name="interviewType" id="second" />
            <label for="second">Second</label>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-y-2">
      <accordion :questionsData="questions" title="About the Candidate">
        <div class="flex flex-col gap-y-3">
          <accordion
            v-for="(question, index) in questions.questions"
            :key="index"
            :isInner="true"
            :questionsData="question"
            :title="question.question"
          >
            <interview-paragraph
              v-if="question.type === 'descriptive'"
              :data="question.value"
            ></interview-paragraph>
          </accordion>
        </div>
      </accordion>
      <accordion :questionsData="questions" title="Role Specific Questions">
        <div class="flex flex-col gap-y-3">
          <accordion
            v-for="(question, index) in questions.questions"
            :key="index"
            :isInner="true"
            :questionsData="question"
            :title="question.question"
          >
            <interview-paragraph
              v-if="question.type === 'descriptive'"
              :data="question.value"
            ></interview-paragraph>
          </accordion>
        </div>
      </accordion>
      <accordion :questionsData="questions" title="Salary & Notice">
        <div class="flex flex-col gap-y-3">
          <accordion
            v-for="(question, index) in questions.questions"
            :key="index"
            :isInner="true"
            :questionsData="question"
            :title="question.question"
          >
            <interview-paragraph
              v-if="question.type === 'descriptive'"
              :data="question.value"
            ></interview-paragraph>
          </accordion>
        </div>
      </accordion>
      <accordion
        :questionsData="questions"
        title="Questions from the Candidate"
      >
        <div class="flex flex-col gap-y-3">
          <accordion
            v-for="(question, index) in questions.questions"
            :key="index"
            :isInner="true"
            :questionsData="question"
            :title="question.question"
          >
            <interview-multi-choice
              v-if="question.type === 'multi-choice'"
              :data="question.options"
            >
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe,
              eaque.
            </interview-multi-choice>
            <interview-paragraph
              v-if="question.type === 'descriptive'"
              :data="question.value"
            ></interview-paragraph>
            <interview-single-choice
              v-if="question.type === 'single-choice'"
              :data="question.options"
            >
            </interview-single-choice>
          </accordion>
        </div>
      </accordion>
    </div>
  </div>
</template>

<script>
import accordion from "@/components/ui/baseComponents/accordion.vue";
import interviewParagraph from "@/components/ui/baseComponents/Interviews/interviewParagraph.vue";
import interviewMultiChoice from "@/components/ui/baseComponents/Interviews/interviewMultiChoice.vue";
import interviewSingleChoice from "@/components/ui/baseComponents/Interviews/interviewSingleChoice.vue";
export default {
  components: {
    accordion,
    interviewParagraph,
    interviewMultiChoice,
    interviewSingleChoice,
  },
  data() {
    return {
      showInfo: false,
      questions: {
        practice: 1,
        role: 3,
        name: "Interview Policy 3",
        questions: [
          {
            question: "Question 1",
            type: "multi-choice",
            options: [
              {
                option: "Option 1 for Question 1",
                selected: false,
              },
              {
                option: "Option 2 for Question 1",
                selected: false,
              },
              {
                option: "Option 3 for Question 1",
                selected: true,
              },
              {
                option: "Option 4 for Question 1",
                selected: false,
              },
              {
                option: "Option 5 for Question 1",
                selected: true,
              },
            ],
          },
          {
            question: "Question 2",
            type: "descriptive",
            value:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse fugiat, assumenda aliquid voluptas impedit modi, provident in sitamet quas temporibus perspiciatis ea suscipit voluptate dictaratione molestiae repellat atque voluptates exercitationemperferendis possimus soluta at illo! Voluptatum, commodi. Dolore,iste qui perspiciatis consectetur esse commodi deleniti! Iddignissimos delectus perferendis ipsa ducimus corporis, quissimilique quibusdam quod at accusamus nostrum ullam laborum ipsamsit sed hic inventore adipisci eveniet, consequuntur iusto nequeet? Nulla quaerat iusto molestiae ipsum, temporibus voluptatequi. Cumque quae esse hic ex soluta mollitia numquam laborumimpedit. Praesentium sunt iste ipsa accusamus suscipit consecteturnemo.",
          },
          {
            question: "Question 3",
            type: "single-choice",
            options: [
              {
                option: "Option 1 for Question 1",
                selected: false,
              },
              {
                option: "Option 2 for Question 1",
                selected: true,
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    showInformation() {
      this.showInfo = !this.showInfo;
    },
  },
};
</script>
<style>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.3s ease-out;
}
.fade-leave-active {
  transition: opacity 0.3s ease-in;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
