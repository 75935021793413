<template>
  <div
    class="border p-5 rounded-3xl w-full bg-lightGrey bg-opacity-50 text-darkGrey"
  >
    <div v-for="(item, index) in data" :key="index">
      <label class="inline-flex items-center">
        <input
          name="options"
          type="radio"
          class="rounded-full text-teal"
          :checked="item.selected"
        />
        <span class="ml-2">{{ item.option }}</span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
