<template>
  <div>
    <p
      class="border p-10 rounded-3xl w-full bg-lightGrey bg-opacity-50 text-darkGrey"
    >
      {{ data }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: String,
      required: true,
    },
  },
};
</script>
