<template>
  <div
    class="border p-5 rounded-3xl w-full bg-lightGrey bg-opacity-50 text-darkGrey"
  >
    <div v-for="(item, index) in data" :key="index">
      <label class="flex items-center space-x-3 mb-3">
        <input
          :checked="item.selected"
          type="checkbox"
          name="checked-demo"
          class="form-tick appearance-none bg-white bg-check h-6 w-6 border border-grey rounded-md checked:bg-teal checked:border-transparent focus:outline-none"
        />
        <span class="font-normal"> {{ item.option }} </span>
      </label>
      <!-- <input
          type="checkbox"
          class="w-6 h-6 rounded text-teal"
          :checked="item.selected"
        />
        <span class="ml-2">{{ item.option }}</span>
      </label> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
